import styled, { DefaultTheme } from "styled-components";

const mapColors = ({ theme }: { theme: DefaultTheme }) =>
  theme.type === "light"
    ? { $bg: theme.colors.white[100] }
    : { $bg: theme.colors.black[900] };

export const ModalContainerWrapper = styled.div.attrs(mapColors)`
  background-color: ${(p) => p.$bg};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;
