import Button from "@material-ui/core/Button";
import { Form, Formik } from "formik";
import { useCallback } from "react";
import Field from "src/components/atoms/Field/field.formik";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { Container, FieldsRow } from "./abbiamo-add-orders.styles";
import { useStoreActions } from "src/hooks/easy-peasy";
import useGetOrder from "src/hooks/useGetOrder/get-order.hooks";
import { OrderStatus } from "src/services/AddOrder/add-order.types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

const options = ["SPO", "ITJ", "CPN", "TIM", "RSU", "BNU", "JAR"];
const type = [
  { label: "Entrega", value: "DELIVERY" },
  { label: "Coleta", value: "COLLECT" },
];
const initialValues = { unity: "SPO", type: "DELIVERY", search_code: "" };

const InputValidation = Yup.object().shape({
  unity: Yup.string().required("Este campo precisa ser preenchido."),
  type: Yup.string().required("Este campo precisa ser preenchido."),
  search_code: Yup.string().required("Este campo precisa ser preenchido."),
});

const AbbiamoAddOrdersForm = () => {
  const { t } = useTranslation("order-component");
  const openOrderDialog = useStoreActions((s) => s.dialogs.abbiamoOrder.open);
  const { mutateAsync } = useGetOrder();
  const handleSubmit = useCallback(
    async (data: typeof initialValues) => {
      try {
        const res: any = await mutateAsync({
          type: data.type,
          unity: data.unity,
          search_code: data.search_code,
        });
        if (res.status === OrderStatus.SUCCESS) {
          openOrderDialog(res.data);
        }
      } catch (error: any) {
        switch (error.response.data.status) {
          case OrderStatus.NOT_FOUND: {
            toast.error(
              <div>
                <ErrorOutlineRoundedIcon fontSize="small" /> Pedido não
                encontrado.
              </div>,
              {
                position: toast.POSITION.BOTTOM_CENTER,
              }
            );
            break;
          }
          case OrderStatus.ERROR:
          default: {
            toast.error(
              <div>
                <ErrorOutlineRoundedIcon fontSize="small" /> Ops, encontramos um
                erro.
              </div>,
              {
                position: toast.POSITION.BOTTOM_CENTER,
              }
            );
            break;
          }
        }
      }
    },
    [mutateAsync, openOrderDialog]
  );

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={InputValidation}
      >
        {({ isSubmitting, handleChange, handleBlur, values }) => (
          <Form>
            <FieldsRow>
              <Field select label={t("unity")} name="unity" options={options} />
              <Field
                select
                label={t("type")}
                name="type"
                value={values.type}
                options={type}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FieldsRow>
            <FieldsRow>
              {values.type === "DELIVERY" ? (
                <Field
                  label="CTRC (somente número, sem dígito verificador)"
                  name="search_code"
                />
              ) : (
                <Field label={t("collect")} name="search_code" />
              )}
            </FieldsRow>
            <FieldsRow>
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                {isSubmitting ? "Carregando..." : "Procurar"}
              </Button>
            </FieldsRow>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AbbiamoAddOrdersForm;
