export interface Body {
  plate: string;
  unity: string;
}

export interface SendPlatesV2Response {
  data: {
    routes: number;
    waypoints: number;
    drivers: {
      ids: string[];
      plates: string[];
      names: string[];
    };
  };
  message: string;
}

export enum RomainStatus {
  SUCCESS = "SUCCESS",
  WAYPOINT_NOT_FOUND = "WAYPOINT_NOT_FOUND",
  DRIVER_NOT_FOUND = "DRIVER_NOT_FOUND",
  WAYPOINT_ALREADY_DELIVERED = "WAYPOINT_ALREADY_DELIVERED",
}
