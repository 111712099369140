import { Container, Image } from './powered-by.styles';

const PoweredBy: React.FC = () => {
  return (
    <Container>
      <Image />
    </Container>
  );
};

export default PoweredBy;
