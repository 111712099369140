import { Action, action } from "easy-peasy";
import { OrderInterface } from "src/services/AddOrder/add-order.types";

export interface AbbiamoOrderStoreModel {
  isOpen: boolean;
  close: Action<AbbiamoOrderStoreModel>;
  open: Action<AbbiamoOrderStoreModel, OrderInterface>;
  data: OrderInterface;
}

const abbiamoOrderStoreModel: AbbiamoOrderStoreModel = {
  isOpen: false,
  data: {
    address: "",
    city: "",
    client_name: "",
    client_document: "",
    code: "",
    neighborhood: "",
    plate: "",
    state: "",
    zipcode: "",
    volume: "",
    type: "",
  },
  close: action((s) => {
    s.isOpen = false;
  }),
  open: action((s, payload) => {
    s.data = payload;
    s.isOpen = true;
  }),
};

export default abbiamoOrderStoreModel;
