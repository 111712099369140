import styled, { DefaultTheme } from "styled-components";

const mapColors = ({ theme }: { theme: DefaultTheme }) =>
  theme.type === "light"
    ? { $color: theme.colors.gray[500] }
    : { $color: theme.colors.gray[800] };

const Divider = styled.div.attrs(mapColors)`
  width: 100%;
  height: 1px;
  background-color: ${(p) => p.$color};
`;

export default Divider;
