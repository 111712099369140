import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
  }
  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
  #root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  html{
    font-size: 62.5%;
    background-color: ${(props) =>
      props.theme.type === "light"
        ? props.theme.colors.white[100]
        : props.theme.colors.black[900]};
    .MuiButton-root {
      box-shadow: none !important;
      /* &.MuiButton-containedPrimary{
        border: 2px solid ${(p) => p.theme.colors.black[1000]}
      } */
      &.MuiButton-outlinedPrimary {
        border-width: 2px;
      }
    }
  }

  div{
    /* padding: 2rem; */
    &#root{
      padding: 0;
    }
  }
  

  .MuiIconButton-root {
    color: inherit !important;
  }


  .Toastify__toast-body{
     font-size: 1.8rem;
  }
  

  @media (max-width: 1080) {
    html {
      font-size: 58%;
    }
  }

  @media (max-width: 600px) {
    html{
      font-size: 50%;
    }
  }

  p {
    font-size: 1.4rem;
  }


  /* *[class^="Mui"]{
    font-size: 1.5rem;
  } */

  @font-face {
    font-family: "EuropaBold";
    src: url("/fonts/EuropaBold/EuropaBold.ttf");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
  }

`;
