import { useCallback } from "react";
import { useToggle } from "react-use";

const useModal = (d = false) => {
  const [state, set] = useToggle(d);
  const close = useCallback(() => {
    set(false);
  }, [set]);
  const open = useCallback(() => {
    set(true);
  }, [set]);
  return [state, { close, open }] as const;
};

export default useModal;
