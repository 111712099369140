import styled from 'styled-components';

export const Image = styled.img.attrs(({ theme }) => ({
  src:
    theme.type === 'light'
      ? '/images/powered_by.svg'
      : '/images/powered_by_dark.svg',
}))`
  height: 40px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 10px 0;
  margin: 0 auto;
`;
