import Button from "@material-ui/core/Button";
import Close from "../close.component";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "src/components/atoms/Divider/divider.component";
import {
  Buttons,
  ButtonRow,
  Dialog,
  Row,
  FieldsRow,
  Column,
} from "../dialogs.styles";
import { useStoreActions, useStoreState } from "src/hooks/easy-peasy";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "src/services/AddOrder/add-order.types";
import { Formik, Form } from "formik";
import Field from "src/components/atoms/Field/field.formik";
import { useCallback } from "react";
import { toast } from "react-toastify";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import * as Yup from "yup";
import useCreateOrderV2 from "src/hooks/useCreateOrderV2/create-order-v2.hooks";
import AbbiamoOrderInfo from "./content.component";

const initialValues = { route_id: "" };

const InputValidation = Yup.object().shape({
  route_id: Yup.string().required("Este campo precisa ser preenchido."),
});

const AbbiamoAddOrderDialog = () => {
  const { t } = useTranslation("order-dialog");
  const { isOpen } = useStoreState((s) => s.dialogs.abbiamoOrder);
  const { close } = useStoreActions((s) => s.dialogs.abbiamoOrder);
  const handleClose = useCallback(() => {
    close();
  }, [close]);
  const { mutateAsync } = useCreateOrderV2();
  const data = useStoreState((state) => state.dialogs.abbiamoOrder.data);

  const handleSubmit = useCallback(
    async (formData) => {
      const routeData = data;
      try {
        await mutateAsync({
          route_id: formData.route_id,
          ...routeData,
        });
        close();
        toast.success(
          <div>
            <CheckCircleOutlineRoundedIcon fontSize="small" /> Pedido adicionado
            com sucesso!
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
      } catch (error: any) {
        switch (error.response.data.status) {
          case OrderStatus.NOT_FOUND: {
            toast.error(
              <div>
                <ErrorOutlineRoundedIcon fontSize="small" /> Rota não
                encontrada.
              </div>,
              {
                position: toast.POSITION.BOTTOM_CENTER,
              }
            );
            break;
          }
          case OrderStatus.WAYPOINT_ALREADY_DELIVERED: {
            toast.error(
              <div>
                <ErrorOutlineRoundedIcon fontSize="small" /> Pedido já utilizado
                em outra rota.
              </div>,
              {
                position: toast.POSITION.BOTTOM_CENTER,
              }
            );
            break;
          }
          case OrderStatus.SELLER_NOT_FOUND: {
            toast.error(
              <div>
                <ErrorOutlineRoundedIcon fontSize="small" /> Cadastro não
                encontrado
              </div>,
              {
                position: toast.POSITION.BOTTOM_CENTER,
              }
            );
            break;
          }
          case OrderStatus.ERROR:
          default: {
            toast.error(
              <div>
                <ErrorOutlineRoundedIcon fontSize="small" /> Ops, encontramos um
                erro.
              </div>,
              {
                position: toast.POSITION.BOTTOM_CENTER,
              }
            );
            break;
          }
        }
      }
    },
    [data, mutateAsync, close]
  );
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Row>
        <DialogTitle>{t("order")}</DialogTitle>
        <Close onClick={handleClose} />
      </Row>
      <Divider />
      <Row>
        <AbbiamoOrderInfo />
      </Row>
      <Column>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={InputValidation}
        >
          {({ isSubmitting }) => (
            <Form>
              <Row>
                <FieldsRow>
                  <Field label="Id da Rota" name="route_id" size="small" />
                </FieldsRow>
              </Row>
              <Divider />
              <ButtonRow>
                <Buttons>
                  <Button
                    variant="contained"
                    color="default"
                    onClick={handleClose}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {isSubmitting ? "Adicionando..." : "Adicionar"}
                  </Button>
                </Buttons>
              </ButtonRow>
            </Form>
          )}
        </Formik>
      </Column>
    </Dialog>
  );
};

export default AbbiamoAddOrderDialog;
