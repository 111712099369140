import { createStore } from "easy-peasy";
import abbiamoOrderStoreModel, {
  AbbiamoOrderStoreModel,
} from "./dialog/abbiamo-order.dialog.store";
import manifestStoreModel, {
  ManifestStoreModel,
} from "./dialog/manifest.dialog.store";
import orderStoreModel, { OrderStoreModel } from "./dialog/order.dialog.store";

export interface IStoreModel {
  dialogs: {
    manifest: ManifestStoreModel;
    order: OrderStoreModel;
    abbiamoOrder: AbbiamoOrderStoreModel;
  };
}

const storeModel: IStoreModel = {
  dialogs: {
    manifest: manifestStoreModel,
    order: orderStoreModel,
    abbiamoOrder: abbiamoOrderStoreModel,
  },
};

export const store = createStore(storeModel);
