import styled from "styled-components";

export const Logo = styled.img.attrs(({ theme }) => ({
  src: `/logos/SchreiberLogo.jpg`,
}))`
  width: 100%;
  max-width: 500px;
  margin: 10px 0;
  padding: 20px;
`;
