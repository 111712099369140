import { MenuItem, TextFieldProps } from "@material-ui/core";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { BaseField } from "./field.styles";

type BaseFieldProps = TextFieldProps;

export type Option =
  | {
      label: string;
      value: string;
    }
  | string;

interface Props {
  options?: Option[];
  namespace?: string;
  translateOptions?: boolean;
}

const mapOptions = (options: Option[], t: TFunction, translate: boolean) =>
  options.map((option) => {
    const label = typeof option === "object" ? option.label : option;
    const value = typeof option === "object" ? option.value : option;
    const key = typeof option === "object" ? option.label : option;
    return (
      <MenuItem key={key} value={value}>
        {translate ? t(label) : label}
      </MenuItem>
    );
  });

export type FieldProps = Props & BaseFieldProps;

const Field: React.FC<FieldProps> = ({
  options,
  namespace,
  translateOptions = false,
  ...props
}) => {
  const { t } = useTranslation(namespace);
  return (
    <BaseField
      {...props}
      fullWidth
      variant="filled"
      children={
        options ? mapOptions(options, t, translateOptions) : props.children
      }
    />
  );
};

export default Field;
