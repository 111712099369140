import { useRouteMatch } from "react-router-dom";
import {
  Container,
  Row,
  LinkContainer
} from "./link-item.styles";
import { useMemo } from "react";

interface Props {
  title: string;
  to: string;
}

const LinkItem: React.FC<Props> = ({
  title,
  to,
}) => {
  const match = useRouteMatch(`/${to}`);
  const isSelected = match?.isExact || false;
  const children = useMemo(
    () => (
      <Container $selected={isSelected}>
        <Row $selected={isSelected}>{title}</Row>
      </Container>
    ),
    [isSelected, title]
  );

  return (
    <LinkContainer to={to}>
      {children}
    </LinkContainer>
  );
};

export default LinkItem;