import { useTranslation } from "react-i18next";
import LinkItem from "src/components/atoms/LinkItem/link-item.component";
import { Container, Links, Wrapper } from "./menu-section.styles";

const newIntegrationMenu = [
  {
    name: "Romaneio",
    to: "abbiamo-roam",
  },
  {
    name: "Pedidos",
    to: "abbiamo-order",
  },
];

const MenuSection = () => {
  const { t } = useTranslation("menu");
  return (
    <Wrapper>
      <Container>
        <Links>
          {newIntegrationMenu.map((menu) => (
            <LinkItem key={menu.name} title={t(menu.name)} to={menu.to}>
              {t(menu.name)}
            </LinkItem>
          ))}
        </Links>
      </Container>
    </Wrapper>
  );
};

export default MenuSection;
