import {
  Column,
  ContentContainer,
  Description,
  Row,
  Title,
} from "../dialogs.styles";
import { useStoreState } from "src/hooks/easy-peasy";

const AbbiamoOrderInfo = () => {
  const data = useStoreState((state) => state.dialogs.abbiamoOrder.data);
  return (
    <ContentContainer>
      <Row>
        <Column>
          <Description>
            <Title>Número do pedido: </Title>
            {data?.code}
          </Description>
          <Description>
            <Title>Número da Placa: </Title>
            {data?.plate}
          </Description>
          <Description>
            <Title>Quantidade: </Title>
            {data?.volume}
          </Description>
          <Description>
            <Title>Nome do cliente: </Title>
            {data?.client_name}
          </Description>
          <Description>
            <Title>Número do documento: </Title>
            {data?.client_document}
          </Description>
        </Column>
        <Column>
          <Description>
            <Title>Endereço: </Title>
            {data?.address}
          </Description>
          <Description>
            <Title>Bairro: </Title>
            {data?.neighborhood}
          </Description>
          <Description>
            <Title>Cidade: </Title>
            {data?.city}
          </Description>
          <Description>
            <Title>Estado: </Title>
            {data?.state}
          </Description>
          <Description>
            <Title>CEP: </Title>
            {data?.zipcode}
          </Description>
        </Column>
      </Row>
    </ContentContainer>
  );
};

export default AbbiamoOrderInfo;
