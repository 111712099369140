import Field, { FieldProps } from "./field.component";
import { useField } from "formik";

interface Props {
  name: string;
}

const FormikField: React.FC<Props & FieldProps> = ({ name, ...props }) => {
  const [field, meta] = useField({ name: name });
  return (
    <Field
      error={Boolean(meta.touched && meta.error)}
      helperText={meta.touched && meta.error ? meta.error : undefined}
      {...field}
      {...props}
    />
  );
};

export default FormikField;
