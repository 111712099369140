import { createContext } from "react";

interface ThemeContext {
  themeType: "light" | "dark";
  setTheme: (theme: "light" | "dark") => void;
}

export const ThemeToggleContext = createContext<ThemeContext>({
  themeType: "light",
  setTheme: () => {},
});
