import BaseDialog from '@material-ui/core/Dialog';
import styled, { DefaultTheme } from 'styled-components';
import { BaseField as Field } from "src/components/atoms/Field/field.styles";

const mapColors = ({ theme }: { theme: DefaultTheme }) =>
  theme.type === 'light'
    ? {
        $progress: theme.colors.black[900],
        $bg: theme.colors.white[1000],
        $color: theme.colors.black[900],
      }
    : {
        $bg: theme.colors.black[900],
        $color: theme.colors.white[1000],
        $progress: theme.colors.white[900],
      };

export const ContentContainer = styled.div`
  padding: 2rem 1rem;
`;

export const Description = styled.p.attrs(mapColors)`
  text-align: left;
  font-size: 1.8rem;
  margin: 1rem 2rem;
  font-weight: normal;
  color: ${(p) => p.$color};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-weight: 600;
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

export const Buttons = styled.div`
  padding: 20px 20px;
  display: flex;
  flex-direction: row-reverse;
  & .MuiButtonBase-root {
    margin-left: 10px;
  }
`;

export const Dialog = styled(BaseDialog).attrs(mapColors)`
  & .MuiLinearProgress-root {
    margin: 10px 20px;
    margin-top: 5px;
    height: 15px;
    border-radius: 4px;
  }
  & .MuiDialog-paperWidthSm {
    max-width: ${(p) => p.theme.maxWidthNumber - 40}px;
  }
  & .MuiPaper-root {
    background-color: ${(p) => p.$bg};
    color: ${(p) => p.$color};
  }
`;

export const FieldsRow = styled.div.attrs(mapColors)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 20px;

  & .MuiIconButton-root {
    color: ${(p) => p.$color} !important;
  }

  ${Field} {
    margin-right: 10px;
  }
  & .MuiButton-containedPrimary {
    margin-left: 10px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    ${Field} {
      margin-bottom: 10px;
    }
    ${Field}:last-of-type {
      margin-bottom: 0;
    }
    & .MuiButton-containedPrimary {
      width: 100%;
      margin-left: unset;
      margin-top: 10px;
      padding: 10px 20px;
      font-size: 2rem;
    }
  }
`;

