import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import AbbiamoRoam from "./pages/abbiamo-roam";
import AbbiamoOrder from "./pages/abbiamo-order";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/abbiamo-roam" />
        </Route>
        <Route path="/abbiamo-roam" component={AbbiamoRoam} />
        <Route path="/abbiamo-order" component={AbbiamoOrder} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
