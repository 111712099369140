import styled, { DefaultTheme } from "styled-components";

const mapColors = ({ theme }: { theme: DefaultTheme }) => ({
  $bg: theme.colors.white[1000],
  $h1: theme.colors.black[900],
  $h2: theme.colors.black[800],
});

export const Links = styled.div.attrs(mapColors)`
  border-radius: ${(p) => p.theme.borderRadius};
  background-color: ${(p) => p.$bg};
  width: 100%;
  padding: 1rem 0;
  padding: 0;
  display: flex;
  align-items: center;
`;

export const Title = styled.h3`
  font-size: 2rem;
  color: ${(p) => p.theme.colors.black[900]};
  margin: 0.5rem 1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 800px;
  min-width: unset;
  width: 100%;
  margin: 0 auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(p) => p.theme.type === "light" && p.theme.border};
  padding: 0 1rem;
  border-radius: 8px;
`;
