import styled, { DefaultTheme } from "styled-components";
import { BaseField as Field } from "src/components/atoms/Field/field.styles";
import { DefaultContainer } from "src/components/atoms/DefaultContainer/default-container.component";

const mapColor = ({ theme }: { theme: DefaultTheme }) =>
  theme.type === "light"
    ? { $color: theme.colors.black[1000] }
    : { $color: theme.colors.white[1000] };

export const Container = styled(DefaultContainer)`
  max-width: 800px;
  min-width: unset;
  border-radius: 20px;
  padding: 30px 10px;
  margin: 20px 0;

  @media (max-width: 800px) {
    max-width: 720px;
    margin: 0 auto;
  }

  & .MuiButton-containedPrimary,
  .MuiButton-text {
    height: 58px;
    padding: 0 40px;
    font-weight: bold;
  }
`;

export const FieldsRow = styled.div.attrs(mapColor)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 20px;

  & .MuiIconButton-root {
    color: ${(p) => p.$color} !important;
  }

  ${Field} {
    margin-right: 10px;
  }
  & .MuiButton-containedPrimary {
    margin-left: 10px;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    ${Field} {
      margin-bottom: 10px;
    }
    ${Field}:last-of-type {
      margin-bottom: 0;
    }
    & .MuiButton-containedPrimary {
      width: 100%;
      margin-left: unset;
      margin-top: 10px;
      padding: 10px 20px;
      font-size: 2rem;
    }
  }
`;
