import api from "..";

import {
  GetOrderRequest,
  GetOrderInfoResponse,
  AddOrder,
} from "./add-order.types";

export const getOrder = (getOrderRequest: GetOrderRequest) =>
  api
    .get<GetOrderInfoResponse>(
      `/waypoint?type=${getOrderRequest.type}&unity=${getOrderRequest.unity}&search_code=${getOrderRequest.search_code}`
    )
    .then((v) => v.data);

export const createOrder = (addOrder: AddOrder) =>
  api.post("/waypoint", addOrder).then((v) => v.data);

export const createOrderV2 = (addOrder: AddOrder) =>
  api.post("/v2/waypoint", addOrder).then((v) => v.data);
