import Button from "@material-ui/core/Button";
import { Form, Formik, FormikHelpers } from "formik";
import { useCallback } from "react";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { toast } from "react-toastify";
import Field from "src/components/atoms/Field/field.formik";
import { Container, FieldsRow } from "./abbiamo-romain-routes-form.styles";
import { sendPlatesV2 } from "src/services/SSWIntegratorV2/ssw-integrator-v2.query";
import { RomainStatus } from "src/services/SSWIntegratorV2/ssw-integrator-v2.types";
import useModal from "src/hooks/useModal/use-modal.hook";
import * as Yup from "yup";

const initialValues = { unity: "SPO", plate: "" };
const InputValidation = Yup.object().shape({
  unity: Yup.string().required("Este campo precisa ser preenchido."),
  plate: Yup.string().required("Este campo precisa ser preenchido."),
});
const options = ["SPO", "ITJ", "CPN", "TIM", "RSU", "BNU", "JAR"];

const AbbiamoRomainRoutesForm = () => {
  const [, { open }] = useModal(false);
  const handleSubmit = useCallback(
    async (
      data: typeof initialValues,
      helpers: FormikHelpers<typeof initialValues>
    ) => {
      try {
        const response = await sendPlatesV2(data);
        open();
        helpers.resetForm();
        if (RomainStatus.SUCCESS) {
          toast.success(
            <span>
              <CheckCircleOutlineIcon /> Rota romaneada com sucesso!
              <br />
              <br />
              Número de rotas: {response.data.routes}
              <br />
              Número de pedidos: {response.data.waypoints}
              <br />
              ID do motorista: {response.data.drivers.ids}
              <br />
              Nome do motorista: {response.data.drivers.names}
            </span>,
            {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: false,
              className: "toast-container",
              draggable: false,
            }
          );
        }
      } catch (error: any) {
        switch (error.response.data.status) {
          case RomainStatus.WAYPOINT_ALREADY_DELIVERED: {
            toast.error(
              <span>
                <ErrorOutlineRoundedIcon fontSize="small" /> Pedidos já foram
                atribuídos em outra rota.
              </span>,
              {
                position: toast.POSITION.BOTTOM_CENTER,
              }
            );
            break;
          }
          case RomainStatus.WAYPOINT_NOT_FOUND: {
            toast.error(
              <span>
                <ErrorOutlineRoundedIcon fontSize="small" /> Nenhum pedido
                encontrado para esta placa.
              </span>,
              {
                position: toast.POSITION.BOTTOM_CENTER,
              }
            );

            break;
          }
          case RomainStatus.DRIVER_NOT_FOUND: {
            toast.error(
              <span>
                <ErrorOutlineRoundedIcon fontSize="small" /> Este motorista não
                foi encontrado.
              </span>,
              {
                position: toast.POSITION.BOTTOM_CENTER,
              }
            );
            break;
          }
          default: {
            toast.error(
              <span>
                <ErrorOutlineRoundedIcon fontSize="small" /> Erro
              </span>,
              {
                position: toast.POSITION.BOTTOM_CENTER,
              }
            );
          }
        }
      }
    },
    [open]
  );
  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={InputValidation}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <>
              <FieldsRow>
                <Field select label="Tipo" name="unity" options={options} />
              </FieldsRow>
              <FieldsRow>
                <Field label="Placa" name="plate" />
              </FieldsRow>
            </>
            <FieldsRow>
              <Button
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                {isSubmitting ? "Carregando..." : "Enviar"}
              </Button>
            </FieldsRow>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default AbbiamoRomainRoutesForm;
