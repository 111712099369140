import i18n from "i18next";
import en from "./en/en.json";
import ptBR from "./pt-br/pt-br.json";
import { initReactI18next } from "react-i18next";
import LngDetector from "i18next-browser-languagedetector";

export const resources = {
  en,
  "pt-BR": ptBR,
} as const;

i18n
  .use(initReactI18next)
  .use(LngDetector)
  .init({
    ns: ["main", "register"],
    resources,
    debug: process.env.NODE_ENV === "development",
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });
