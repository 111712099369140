import styled, { DefaultTheme, css } from "styled-components";
import ButtonBase from "@material-ui/core/ButtonBase";
import { transparentize } from "polished";
import { Link } from "react-router-dom";

const mapColors = ({ theme }: { theme: DefaultTheme }) =>
  theme.type === "light"
    ? { $title: theme.colors.black[1000], $border: theme.colors.gray[500] }
    : { $title: theme.colors.white[1000], $border: theme.colors.black[700] };

export const Container = styled.div.attrs(mapColors)<{ $selected: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  padding: 3px 0;
  border-bottom: ${(p) =>
    p.$selected && `2px solid ${p.theme.colors.main[500]}`};
    `;
    
    export const Row = styled(ButtonBase).attrs(mapColors)<{ $selected: boolean }>`
    &.MuiButtonBase-root {
      text-align: left;
      width: 100%;
      font-size: 2rem;
      font-weight:  ${(p) => (p.$selected ? `bold` : `normal`)};
    color: ${(p) => (p.disabled ? transparentize(0.5, p.$title) : p.$title)};
    cursor: ${(p) => (p.disabled ? `default` : `pointer`)};
    padding: 10px 20px;
  }
`;

const ContainerStyled = css`
  height: 100%;
`;

export const LinkContainer = styled(Link)`
  ${ContainerStyled}
`;