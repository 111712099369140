import { TextField } from "@material-ui/core";
import styled from "styled-components";

export const BaseField = styled(TextField)`
  & .MuiFilledInput-root {
    border-radius: 5px;
    align-item: start;
    justify-content: start;
    text-align: start;
  }
  & .MuiFilledInput-underline:before {
    content: unset;
  }
  & .MuiFilledInput-underline:after {
    content: unset;
  }
  & .MuiFilledInput-input:-webkit-autofill {
    /* -webkit-box-shadow: 0 0 0 100px ${(p) =>
      p.theme.colors.main[500]} inset; */
  }
`;
