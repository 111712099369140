import { Action, action } from 'easy-peasy';
import { OrderInterface } from 'src/services/AddOrder/add-order.types';

export interface OrderStoreModel {
  isOpen: boolean;
  close: Action<OrderStoreModel>;
  open: Action<OrderStoreModel, OrderInterface>;
  data: OrderInterface;

}

const orderStoreModel: OrderStoreModel = {
  isOpen: false,
  data: {address: '', city: '', client_name: '', client_document: '', code: '', neighborhood: '', plate: '', state: '', zipcode: '', volume: '', type: ''},
  close: action((s) => {
    s.isOpen = false;
  }),
  open: action((s, payload) => {
    s.data = payload;
    s.isOpen = true;
  }),
};

export default orderStoreModel;
