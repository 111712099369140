export const theme = {
  borderRadius: "5px",
  border: "1px solid #DFDFDF",
  type: "light",
  maxWidthNumber: 1200,
  maxWidth: "1200px",
  defaultShadow: "0px 4px 4px rgba(172, 172, 172, 0.25)",
  colors: {
    main: {
      500: "#2e3192",
    },
    red: {
      500: "#f44336",
    },
    black: {
      700: "#3C4042",
      800: "#2D3133",
      900: "#202124",
      950: "#101010",
      1000: "#000",
    },
    white: {
      500: "#F0F0F0",
      700: "#9A9A9A",
      900: "#b7b7b7",
      950: "#DDDDDD",
      1000: "#fff",
    },
    gray: {
      300: "#efefef",
      500: "#DEDEDE",
      800: "#3e3e3e",
    },
  },
} as const;

export type ITheme = typeof theme;
