import { Action, action } from 'easy-peasy';
import { ManifestInterface } from 'src/services/TransferManifest/transfer-manifest.types';

export interface ManifestStoreModel {
  isOpen: boolean;
  close: Action<ManifestStoreModel>;
  open: Action<ManifestStoreModel, ManifestInterface[]>;
  data: ManifestInterface[];
}

const manifestStoreModel: ManifestStoreModel = {
  isOpen: false,
  data: [],
  close: action((s) => {
    s.isOpen = false;
  }),
  open: action((s, payload) => {
    s.data = payload;
    s.isOpen = true;
  }),
};

export default manifestStoreModel;
