import {
    ExtendButtonBase,
    IconButton,
    IconButtonTypeMap,
  } from '@material-ui/core';
  
  import CloseIcon from '@material-ui/icons/Close';
  
  const Close: ExtendButtonBase<
    IconButtonTypeMap<Record<string, unknown>, 'button'>
  > = (props) => {
    return (
      <IconButton
        {...props}
        style={{ margin: '10px 10px 10px auto' }}
        color="inherit"
      >
        <CloseIcon color="inherit" />
      </IconButton>
    );
  };
  
  export default Close;