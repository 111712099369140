import { useTitle } from "react-use";
import FullLogo from "src/components/atoms/FullLogo/full-logo.component";
import MenuSection from "src/components/organisms/MenuSection/menu-section.component";
import { ModalContainerWrapper } from "./wrappers";
import PoweredBy from "src/components/atoms/PoweredBy";
import AbbiamoRomainRoutesForm from "src/components/organisms/AbbiamoRomainRoutesForm/abbiamo-romain-routes-form.component";

const AbbiamoRoam = () => {
  useTitle("Romaneio Abbiamo");
  return (
    <>
      <ModalContainerWrapper>
        <FullLogo />
        <MenuSection />
        <AbbiamoRomainRoutesForm />
        <PoweredBy />
      </ModalContainerWrapper>
    </>
  );
};

export default AbbiamoRoam;
