export interface OrderInterface {
  code: string;
  client_name: string;
  client_document: string;
  address: string;
  neighborhood: string;
  zipcode: string;
  city: string;
  state: string;
  volume: string;
  plate: string;
  type: string;
}

export enum OrderStatus {
  NOT_FOUND = "NOT_FOUND",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  WAYPOINT_ALREADY_DELIVERED = "WAYPOINT_ALREADY_DELIVERED",
  SELLER_NOT_FOUND = "SELLER_NOT_FOUND",
}

export type GetOrderInfoResponse = {
  status: OrderStatus.NOT_FOUND;
} & {
  status: OrderStatus.ERROR;
} & {
  status: OrderStatus.SUCCESS;
  orderList: OrderInterface;
};

export interface GetOrderRequest {
  type: string;
  unity: string;
  search_code: string;
}

export interface AddOrder extends OrderInterface {
  route_id: string;
}
