import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { theme as rawTheme } from "src/theme";
import { GlobalStyles } from "src/theme/global";
import { ThemeProvider } from "styled-components";
import "src/i18n/config";
import { ThemeToggleContext } from "./hooks/useThemeToggler/use-theme-toggler.context";
import useThemeTogglerRoot from "./hooks/useThemeToggler/use-theme-toggler.root";
import { StoreProvider } from "easy-peasy";
import { store } from "./store/root.store";
import { useMemo } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchInterval: false,
    },
  },
});

const Deps: React.FC = ({ children }) => {
  const themeManagerRoot = useThemeTogglerRoot();
  const theme = useMemo(
    () => ({ ...rawTheme, type: themeManagerRoot.themeType as any }),
    [themeManagerRoot.themeType]
  );
  const muiTheme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: theme.type,
          primary: {
            main: theme.colors.main[500],
            contrastText: theme.colors.white[100],
          },
          text: {
            primary:
              theme.type === "light"
                ? theme.colors.black[1000]
                : theme.colors.white[1000],
          },
        },

        typography: {
          fontSize: 25,
        },
      }),
    [theme]
  );
  return (
    <StoreProvider store={store}>
      <ThemeToggleContext.Provider value={themeManagerRoot}>
        <QueryClientProvider client={queryClient}>
          <MuiThemeProvider theme={muiTheme}>
            <ThemeProvider theme={theme}>
              {children}
              <ToastContainer autoClose={8000}/>
              <GlobalStyles />
            </ThemeProvider>
          </MuiThemeProvider>
        </QueryClientProvider>
      </ThemeToggleContext.Provider>
    </StoreProvider>
  );
};

export default Deps;
