import { useLocalStorage } from "react-use";

const useThemeTogglerRoot = () => {
  const [themeType, setTheme] = useLocalStorage<"light" | "dark">(
    "theme",
    "light"
  );
  const setThemeType = (theme: "light" | "dark") => {
    setTheme(theme);
  };
  return { setTheme: setThemeType, themeType: themeType || "light" };
};

export default useThemeTogglerRoot;
