import { useTitle } from "react-use";
import FullLogo from "src/components/atoms/FullLogo/full-logo.component";
import MenuSection from "src/components/organisms/MenuSection/menu-section.component";
import PoweredBy from "src/components/atoms/PoweredBy";
import { ModalContainerWrapper } from "./wrappers";
import AbbiamoAddOrderDialog from "src/components/organisms/Dialogs/AbbiamoOrder/abbiamo-add-order.dialog.component";
import AbbiamoAddOrdersForm from "src/components/organisms/AbbiamoAddOrders/abbiamo-add-orders.component";

const AbbiamoOrder = () => {
  useTitle("Romaneio e Transferência");
  return (
    <>
      <ModalContainerWrapper>
        <FullLogo />
        <MenuSection />
        <AbbiamoAddOrdersForm />
        <AbbiamoAddOrderDialog />
        <PoweredBy />
      </ModalContainerWrapper>
    </>
  );
};

export default AbbiamoOrder;
